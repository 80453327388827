<template>
    <div class="datum">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="datum_header">
            <div class="datum_header_left">海量资料，实用高效！</div>
            <div class="ask_header_box_right">
                <input v-model="searchText" placeholder="快速检索您想要的资料"  @keyup.enter.prevent="searchEnterFun($event)" />
                <img src="@/assets/datum/search.png" alt="" @click="searchFile" >
            </div>
        </div>
        <div class="datum_main">
            <div class="datum_main_left">
                <div class="datum_main_left_classify">
                    <div class="datum_main_left_classify_first">
                        <p class="datum_main_left_classify_first_title">科目:</p>
                        <div class="datum_main_left_classify_first_name">
                            <p :class="oneTypeId==item.id?'selectp':''" v-for="(item,index) in datumFirstList" :key="index" @click="getOneType(item)">{{item.name}}</p>
                        </div>
                    </div>
                    <div class="datum_main_left_classify_second">
                        <p class="datum_main_left_classify_second_title"></p>
                        <div class="datum_main_left_classify_second_name">
                            <p :class="twoTypeId==val.id?'selectp':''" v-for="(val,key) in datumSecondList" @click="getTwoType(val)" :key="key">{{val.name}}</p>
                        </div>
                    </div>
                </div>
                <div class="datum_main_left_list">
                    <div class="datum_main_left_list_header">【{{oneTypeName}}】>【{{twoTypeName}}】相关知识资料下载</div>
                    <div class="datum_main_left_list_box">
                        <div class="datum_main_left_list_box_item" v-for="(item,index) in datumList" :key="index" @click="goDetail(item)">
                            <div class="datum_main_left_list_box_item_left">
                                <img v-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='pdf'" src="@/assets/pdf.png" alt="">
                                <img v-else-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='doc' || item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='docx'" src="@/assets/doc.png" alt="">
                                <img v-else-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='ppt'" src="@/assets/ppt.png" alt="">
                                <img v-else-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='xls' 
                                    || item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='xlsx'
                                    || item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='csv'" src="@/assets/xls.png" alt="">
                                <img v-else src="@/assets/weizhi.png" alt="">
                                <div class="datum_main_left_list_box_item_left_content">
                                    <p>
                                        <span class="tuijian" v-if="item.isHot==1">推荐</span>
                                        <span class="filename">{{item.materialName}}</span>
                                    </p>
                                    <p>
                                        <span>{{item.materialSize}}KB</span>
                                        <span>下载 {{item.downloadCount}} 次</span>
                                    </p>
                                </div>
                            </div>
                            <div class="datum_main_left_list_box_item_right">
                                <el-button plain @click.stop="goHandouts(item)">下载</el-button>
                            </div>
                        </div>
                        <div class="information_main_left_pagination" v-if="datumList.length">
                            <el-pagination layout="prev, pager, next"  :total="count" @current-change="currentChange"></el-pagination>
                        </div>
                        <div class="coursediv_empty" v-if="datumList.length==0">
                            <div class="coursediv_empty_img">
                                <img src="@/assets/class_empty.png" alt="">
                            </div>
                            <div class="coursediv_empty_text">无相关内容</div>
                        </div>
                        

                    </div>
                </div>
                
            </div>
            <div class="datum_main_right">
                <div class="datum_main_right_header">热门下载</div>
                <div class="datum_main_right_list">
                    <div class="datum_main_right_list_item" v-for="(item,index) in datumHotList" :key="index" @click="goDetail(item)" >
                        <p style="cursor:pointer;" class="longtext_one">{{item.materialName}}</p>
                        <p style="font-size:12px;">下载 {{item.downloadCount}} 次</p>
                    </div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
        <!-- <popup_login :todos="todos" :isType="isType"  @setTodos="getTodos"></popup_login> -->
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
// import popup_login from '@/components/popup_login/index.vue'
import { material_firstType,material_secondType,material_list,related_list,add_download } from "@/api/other";
export default {
    name:'datum',
    components: {
        t_header,
        t_footer,
        // popup_login
    },
    data(){
        return{
            isSort:false,
            // todos:false,
            isType:false,
            oneTypeId:'0',
            twoTypeId:'0',
            datumFirstList:[],
            datumSecondList:[],
            firstTypeId:0,
            secondTypeId:0,
            datumList:[],
            page:0,
            count:0,
            searchText:'',
            oneTypeName:'',
            twoTypeName:'',
            datumHotList:[],
            isUserInfo:false,
            
        }
    },
    mounted(){
        this.material_firstType();
        this.material_hot_list();
    },
    methods:{
        searchEnterFun(e){
            var keyCode = window.event ? e.keyCode : e.which;
            console.log("回车搜索", keyCode, e);
            if (keyCode == 13) {
                this.searchFile()
            }
        },
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        currentChange(page){
            this.page = page-1;
            this.material_list();
            // this.information_list2();
        },
        searchFile(){
            console.log(this.searchText);
            // this.material_search_list();
            let routeUrl =this.$router.resolve({
                path:'/searchpage',
                query:{
                    searchText:this.searchInput,
                    num:3
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        goDetail(item){
            console.log(item)
            let routeUrl =this.$router.resolve({
                path: '/datumDetail', query: {
                    item:JSON.stringify(item)
                }
            }); 
            window.open(routeUrl.href, '_blank');
        },
        // 下载
        goHandouts(item){
            console.log(item)
            const userInfo = localStorage.getItem("setuserInfo");
            console.log(userInfo);
            console.log(userInfo == 'null');
            if(userInfo==null || userInfo == 'null'){
                this.isUserInfo=true
                return false
            }

            var obj={
                materialId:item.materialId
            }
            this.add_download(obj);
            console.log(item.filePath);
            window.open(item.filePath, '_blank');
        },
        // 增加下载量
        add_download(obj){
            add_download(obj).then(res=>{
                console.log(res)
                console.log('下载了')
            }).catch(error=>{
                console.log(error)
            })
        },
        getOneType(item){
            console.log(item)
            this.oneTypeId=item.id
            this.firstTypeId=item.id
            this.oneTypeName=item.name
            this.material_secondType()
        },
        getTwoType(item){
            console.log(item)
            this.twoTypeId=item.id
            this.secondTypeId=item.id
            this.twoTypeName=item.name
            this.material_list()
        },
        // 资料一级分类
        material_firstType(){
            material_firstType().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.datumFirstList=res.data.data
                    this.firstTypeId=res.data.data[0].id
                    this.oneTypeId=res.data.data[0].id
                    this.oneTypeName=res.data.data[0].name
                    this.material_secondType()
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 资料二级分类
        material_secondType(){
            var obj={
                firstTypeId:this.firstTypeId
            }
            material_secondType(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.datumSecondList=res.data.data
                    this.secondTypeId=res.data.data[0].id
                    this.twoTypeId=res.data.data[0].id
                    this.twoTypeName=res.data.data[0].name
                    this.material_list()
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        material_list(){
            var obj = {
                firstTypeId:this.firstTypeId,
                secondTypeId:this.secondTypeId,
                isHot:'',
                page:this.page,
                search:this.searchText,
            }
            material_list(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.count = res.data.data.count;
                    this.datumList=res.data.data.materialList;
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
         material_hot_list(){
            var obj = {
                firstTypeId:'',
                secondTypeId:'',
                isHot:1,
                page:this.page,
                search:this.searchText,
            }
            material_list(obj).then(res=>{
                if(res.data.code==0){
                    // this.datumHotList=res.data.data.materialList
                    var arr = res.data.data.materialList.slice(0,5);
                    this.datumHotList=arr;
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        material_search_list(){
            var obj = {
                firstTypeId:this.firstTypeId,
                secondTypeId:this.secondTypeId,
                isHot:'',
                page:this.page,
                search:this.searchText,
            }
            material_list(obj).then(res=>{
                if(res.data.code==0){
                    this.datumList=res.data.data.materialList
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        }

    }
}
</script>

<style>
    .longtext_one {
        width: 100%; 
        overflow:hidden; 
        white-space: nowrap;
        text-overflow:ellipsis;
        text-align:left;
    }
    .tuijian{
        width: 44px;
        height: 18px;
        background: linear-gradient(90deg, #FFC26F 0%, #FF8E3C 100%);                                      
        border-radius: 100px 100px 100px 0px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
    .filename{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
</style>